<section class="inicio">

    <div class="listado-vidrieras">

      <div class="titulo">Tipos de vidrieras digitales</div>

      <a routerLink="./smart">
        Vidriera Smart
      </a>
      <a routerLink="./folletos">
        Diapositivas de folletos
      </a>

    </div>
</section>
