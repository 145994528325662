import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LoaderComponent {

	@Input() show_animation: boolean;
	@Input() mensaje: string;

	public visible: boolean;

	constructor(private cdRef: ChangeDetectorRef){

		this.show_animation = true;
		this.mensaje = 'Cargando ..';
		this.visible = true;
	}

	ngOnChanges(change){

		if ( typeof this.show_animation == 'string' )
			this.show_animation =  this.show_animation == 'true' ? true : false;
		else if ( typeof this.show_animation != 'boolean' )
			this.show_animation = true;

		this.detectChanges();
	}

	setMensaje(value){

		this.mensaje = value;
		this.detectChanges();
	}

	show(){

		this.visible = true;
		this.detectChanges();
	}

	hidden(){

		this.visible = false;
		this.detectChanges();
	}

	detectChanges(){
		this.cdRef.detectChanges();
	}
}
