import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { LoginComponent } from './components/login/login.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', component: InicioComponent },
  { path: 'smart', loadChildren: () => import('./vidriera-smart/vidriera-smart.module').then(m => m.VidrieraSmartModule) },
  { path: 'folletos', loadChildren: () => import('./vidriera-folletos/vidriera-folletos.module').then(m => m.VidrieraFolletosModule) },
  { path: 'login', component: LoginComponent },
  { path: 'inmobiliaria/:inmobiliaria/smart', loadChildren: () => import('./vidriera-smart/vidriera-smart.module').then(m => m.VidrieraSmartModule) },
  { path: 'inmobiliaria/:inmobiliaria/folletos', loadChildren: () => import('./vidriera-folletos/vidriera-folletos.module').then(m => m.VidrieraFolletosModule) },
  { path: 'inmobiliaria/:inmobiliaria', component: InicioComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
