import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Componentes
import { LoaderComponent } from './components/loader/loader.component';

import { DialogComponent } from './components/dialog/dialog.component';
import { CodigoQRComponent } from './components/codigo-qr/codigo-qr.component';

@NgModule({
	declarations: [
    LoaderComponent,
		DialogComponent,
		CodigoQRComponent
	],
	imports: [
		CommonModule
	],
	exports: [
    LoaderComponent,
		DialogComponent,
		CodigoQRComponent
	],
	providers: []
})

export class GlobalComponentsModule{}
