<div class="loader-component" *ngIf="visible">

	<ng-template [ngIf]="show_animation" [ngIfElse]="imagenDefault">

        <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"></circle>
        </svg>

	</ng-template>

	<ng-template #imagenDefault>

		<img src="./assets/images/logo-mardelinmueble.png">

	</ng-template>

	<span>{{ mensaje }}</span>

</div>
