import { Component, Input, Output, EventEmitter } from '@angular/core';
import QRCode from 'qrcode';


@Component({
  selector: 'codigo-qr',
  templateUrl: './codigo-qr.component.html',
  styleUrls: ['./codigo-qr.component.css']
})

export class CodigoQRComponent {

	@Input() texto: string = '';
	@Input() width: number = 256;
	@Input() colordark: string = '#000000';
  	@Input() colorlight: string = '#ffffff';

  	@Output() eventSuccess = new EventEmitter();

  	public id: number;

	constructor(){

		this.id = this.crearNumeroIdRandom();
		//console.log('id del component: ', this.id);
	}

	ngOnChanges(changes){

		// tengo que chequear que el componente DOM en el html este creado
		// para poder intentar crear el nuevo codigo QR
		let elem = document.getElementById('codigo-qr-component-'+this.id);

		if ( elem && this.texto.length > 0 )
			this.crearCodigoQR();
	}
/*
	ngOnInit(){
		// No puedo llamar al metodo que crea el codigo QR en el canvas del DOM
		// en este ciclo, porque todavia no esta creado el elemento en el DOM
		//this.crearCodigoQR();
	}
*/
	ngAfterViewInit(){
		
		// tengo que chequear que el componente DOM en el html este creado
		// y que tenga la propiedad [texto] seteado desde el template padre
		// para poder intentar crear el nuevo codigo QR
		let elem = document.getElementById('codigo-qr-component-'+this.id);

		if ( elem && this.texto.length > 0 )
			this.crearCodigoQR();
	}

	crearNumeroIdRandom(){

		return Math.floor(Math.random() *  999999);
	}

	setTexto(value=null){

		if ( !value ) return;

		this.texto = value;
	}

	crearCodigoQR() {

		try {
			
			let elem = document.getElementById('codigo-qr-component-'+this.id);

			if ( ! elem )
				throw new Error('No se encontro el elemento canvas en el DOM.');

			if ( ! this.isValidoElTextoACodificar(this.texto) )
				throw new Error('El texto a codificar esta vació o no es valido.');

			let options = {
				width: this.width,
				color: {
					dark: this.colordark,
					light: this.colorlight
				}
			};

			QRCode.toCanvas(elem, this.texto, options, (error, elemento)=>{

				if ( error )
					throw new Error(error);

				//console.log('elemento canvas del DOM: ', elemento);
				this.emitEventoSuccess(true);
			});
		} catch (e) {
	      	console.error('Error en el componente codigo-qr: ' + e.message);
	      	this.emitEventoSuccess(false, e.message);
	    }
	}

	isValidoElTextoACodificar(value) {

		return !(typeof value === 'undefined' || value === '');
	}

	emitEventoSuccess(value=null, error=null){

		if ( typeof value != "boolean" ) return;

		this.eventSuccess.emit({ success: value, error });
	}
}