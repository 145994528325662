import { Component } from '@angular/core';

@Component({
  selector: 'app-404',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})

export class PageNotFoundComponent {

  constructor(){}
}
