<section class="login login-background">

    <div class="login-box">

        <div class="titulo">
            Ingresá tus datos por favor
        </div>

        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-login form-material">

            <div class="form-group m-t-40">

                <input class="form-control" type="text" placeholder="Tu correo electrónico" formControlName="email">
                <span *ngIf="form.get('email').touched && form.get('email').hasError('required')">
                    El correo electrónico es obligatorio.
                </span>
                <span *ngIf="form.get('email').touched && form.get('email').hasError('email')">
                    El correo electrónico no es valido.
                </span>
            </div>

            <div class="form-group m-t-20">

                <input class="form-control" type="password" placeholder="Tu contraseña" formControlName="password">
                <span *ngIf="form.get('password').touched && form.get('password').invalid">
                    La contraseña es obligatoria.
                </span>
            </div>

            <button class="btn-login" type="submit" [disabled]="form.invalid">
                Entrar
            </button>

            <div *ngIf="status=='error'" class="mensaje-error">
                <p>El correo electrónico o la contraseña no son correctos.</p>
            </div>

        </form>

    </div>
</section>
