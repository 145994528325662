import { Component } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public show_loading: boolean;

  constructor(private _router: Router){

    this._router.events.subscribe(event => {
        if (event instanceof RouteConfigLoadStart) {
          this.show_loading = true;
        } else if (event instanceof RouteConfigLoadEnd) {
          this.show_loading = false;
        }
    });
  }
}
