import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

// Services
import { GLOBAL } from './global';
import { EncriptationService } from './encriptation.service';

// Inyectando el servicio al root, esto permite
// que el servicio este disponible en toda la app
// El inyector raiz ( root ) es el encargado de crear una
// instancia de AuthenticationService
@Injectable({
  providedIn: 'root',
})

export class AuthenticationService {

	// Los servicios no pueden producir eventos con eventEmitter, para eso
	// usamos estos Subjects que devuelven un observable
	// Un Subject es un objeto observable que puede manejar multiples subscripciones
	// y ademas puede emitir eventos.
	//private countdownEndSource = new Subject<void>(); // http://reactive.io/rxjs
	//public countdownEnd$ = this.countdownEndSource.asObservable();

	// El behaviorSubject es como un Subject salvo por el tema del estado, el
	// behaviorSubject siempre tiene un valor, por eso al crearlo hay que inicializarlo.
	// Al suscribirte a un behaviorSubject obtienes al ultimo valor disponible.
	private identitySubject = new BehaviorSubject<any>(null);
	public identity$ = this.identitySubject.asObservable();

	private tokenSubject = new BehaviorSubject<any>(null);
	public token$ = this.tokenSubject.asObservable();

	public identity: any;
	public token: any;
	public admin: boolean;

	// Esta propiedad es siempre va a estar en true, para que cuando
	// el usuario refresque el navegador, o cierre el navegador y vuelva a entrar
	// y se controle el token.
	// La unica vez que lo pongo en false, es cuando el usuario entra por login,
	// entonces no hace falta revalidar el token, porque recien ingreso
	public validate_token: boolean;

  public token_valido: boolean;

	constructor(private _http: HttpClient,
                private _encriptationService: EncriptationService) {

		console.log('constructor del AuthenticationService');

		this.identity = this.getItemLocalStorage('identity');
		this.token = this.getItemLocalStorage('token');
		this.admin = false;

		this.validate_token = true;
    this.token_valido = false;

		if ( this.identity ) {

			this.identity = JSON.parse(this.identity);
			this.identitySubject.next(this.identity);

			this.admin = this.identity && this.identity.admin ? true : false;
		}
	}
/*
	login(email, password): Observable<any>{

		const url = GLOBAL.url_back_end + 'gestion/agentes/login';

		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(url, { email: email, password: password }, { headers: headers });
	}
*/
    login(email, password): Observable<any>{

        var email_encrypted = this._encriptationService.set(email);
        var pass_encrypted = this._encriptationService.set(password);

		const url = GLOBAL.url_back_end + 'gestion/agentes/login';

		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(url, { email: email_encrypted, password: pass_encrypted }, { headers: headers });
	}

	logout() {
        console.log('2 ) entro al logout');
        // Al limpiar los datos guardados en local storage,
        // se envian los valores ( null ) a los observadores
        // de las propiedades identity y token, y la app (main-layout.component)
        // respondera automaticamente
        this.clearLocalStorage();
    }

    isAuthenticated() {

    	return ( typeof this.identity === 'undefined' || this.identity === null || this.identity === '' ) ? false : true;
    }

    isAdmin(){

    	return this.admin ? true : false;
    }

    setNoValidateToken(){

    	this.validate_token = false;
    }

    setIdentity(agente=null, set_observable=true){
    	console.log('entro a setIdentity');
    	this.identity = agente;
    	this.setItemLocalStorage('identity', JSON.stringify(agente));

    	this.admin = this.identity && this.identity.admin ? true : false;

    	if ( set_observable )
    		this.identitySubject.next(agente);
    }

    setToken(value=null){
    	console.log('entro a setToken');
    	this.token = value;
		this.setItemLocalStorage('token', value);

		this.tokenSubject.next(this.token);
	}

    getIdentity() {

    	return this.identity ? this.identity : null;
	}

	getToken() {

		return this.token ? this.token : null;
	}

	validateToken(): Observable<any>{

		return new Observable((observer) => {

			if ( ! this.validate_token ) { // return;
				observer.next(true);
				observer.complete();
			}

			if ( typeof this.token === 'undefined' || this.token === null || this.token === '' ) {
				observer.error({ error: true, mensaje: 'El token esta vacio o no es valido.' });
				observer.complete();
			}

			this.validateTokenEnElServidor(this.token).subscribe(
				(response)=>{

					if ( response.success ) {

						// Le digo que no lo difunda a los observadores de identity$
						// porque no tiene sentido, porque el agente es correcto
						// y los datos no cambiaron
						this.setIdentity(response.agente, false);

						// El token si lo asigno, porque viene con un nuevo token
						// con fecha de expiracion renovada
						this.setToken(response.token);

            this.setTokenValido(true);

						//return true;
						observer.next(true);
						observer.complete();
					}
				},
				(error)=>{

					console.log('entro al error de validateTokenEnElServidor: ', error);

					//this.setIdentity(null);
					//this.setToken(null);
					observer.error({ error: true, mensaje: error.error.message });
					observer.complete();
				});
		});
	}

  setTokenValido(value) {

    if ( typeof value != "boolean" ) return;

    this.token_valido = value;
  }

  isTokenValido(): boolean {

    return this.token_valido;
  }

	validateTokenEnElServidor(token=null): Observable<any> {

		const url = GLOBAL.url_back_end + 'gestion/agentes/validate-token/';

		return this._http.get(url + token);
	}

	setItemLocalStorage(key, value){

		localStorage.setItem(key, value);
	}

	getItemLocalStorage(key){

		return key ? localStorage.getItem(key) : null;
	}

	clearLocalStorage(){
		console.log('3) entro a clearLocalStorage');
		localStorage.clear();
		console.log('4) paso el localStorage');

		this.identity = null;
		this.token = null;

		this.identitySubject.next(null);
        this.tokenSubject.next(null);
	}
}
