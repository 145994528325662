//const URL_SERVER = 'http://192.168.1.101:4000';
const URL_SERVER = 'https://api.mardelinmueble.com';

export var GLOBAL = {
	url_server: URL_SERVER,
	url_back_end: URL_SERVER + '/v3/',
	url_images_agentes: URL_SERVER + '/uploads/agentes/',
	url_images_inmobiliarias: URL_SERVER + '/uploads/inmobiliarias/',
	url_images_inmuebles: URL_SERVER + '/uploads/inmuebles/',
	url_thumbnails_inmuebles: URL_SERVER + '/uploads/inmuebles/thumbnails/',
	url_webs: URL_SERVER + '/uploads/webs/',
	url_webs_default: URL_SERVER + '/uploads/webs/default/',
	url_vidrieras: URL_SERVER + '/uploads/vidrieras/',
	url_images_templates: URL_SERVER + '/uploads/templates/',
	url_images_vidrieras: URL_SERVER + '/uploads/vidrieras/',
	url_thumbnails_vidrieras: URL_SERVER + '/uploads/vidrieras/thumbnails/',
	url_images_folletos: URL_SERVER + '/uploads/folletos/',
	url_thumbnails_folletos: URL_SERVER + '/uploads/folletos/thumbnails/',
	url_archivos_folletos_personalizados: URL_SERVER + '/uploads/folletos/personalizados/',
	url_thumbnails_folletos_personalizados: URL_SERVER + '/uploads/folletos/personalizados/thumbnails/'
}