import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';

// Services
import { AuthenticationService } from '../../services/authentication.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css', '../../../assets/css/form-material.css']
})

export class LoginComponent {

	public status: 'success' | 'error';
	public form: UntypedFormGroup;
	public ir_url: string;

	constructor(private _fb: UntypedFormBuilder,
				private _route: ActivatedRoute,
				private _router: Router,
				private _authenticationService: AuthenticationService
	){

		if ( this._authenticationService.isTokenValido() )
            this._router.navigate(['/']);
	}

	ngOnInit(){
		console.log('LoginComponent cargado ..');

		this.createForm();

		document.documentElement.scrollTop = 0;

		// get return url from route parameters or default to '/'
        this.ir_url = this._route.snapshot.queryParams['returnUrl'] || '/';
	}

	createForm() {

		this.form = this._fb.group({
			email: ['', [Validators.required, Validators.email]	],
			password: ['', Validators.required]
		});
	}

	onSubmit(){

		if ( this.form.get('email').valid && this.form.get('password').valid ) {

			this._authenticationService.login(this.form.get('email').value, this.form.get('password').value).subscribe(
				(response) => {

					if ( response.success ) {

						this.status = 'success';

						this._authenticationService.setNoValidateToken();
						this._authenticationService.setIdentity(response.agente);
						this._authenticationService.setToken(response.token);

						this._router.navigate([this.ir_url]);
					}
				},
				(error) => {
					this.status = 'error';
				}
			);
		}
	}
}
