import { Component, ViewEncapsulation } from '@angular/core';
import swal from "sweetalert";

const DialogTypes = {
	    SUCCESS: 'success',
	    ERROR: 'error',
	    INFO: 'info',
	    WARNING: 'warning'
	}

@Component({
	selector: 'app-dialog',
	template: '',
	encapsulation: ViewEncapsulation.None
})

export class DialogComponent{

	public type: string;
	public title: string;
	public message: string;
	public icon: string;

	constructor(){
		this.type = DialogTypes.INFO; // Por defecto
		this.title = '';
		this.message = '';
		this.icon = '';
	}
 
    success(message: string) {
        return this.showDialog(DialogTypes.SUCCESS, message);
    }
 
    error(message: string) {
        return this.showDialog(DialogTypes.ERROR, message);
    }
 
    info(message: string) {
        return this.showDialog(DialogTypes.INFO, message);
    }
 
    confirmation(message: string) {
        return this.showDialog(DialogTypes.WARNING, message);
    }
 
    showDialog(type: string, message: string): Promise<boolean> {

    	return new Promise(async(resolve, reject)=>{

    		this.type = type ? type : DialogTypes.INFO;
			this.message = message ? message : '';

			let options = await this.getPropiedadesParaSWAL();

			this.createComponenteSWAL(options)
				.then((response)=>{
					resolve(response);
				});
    	});
    }

	getPropiedadesParaSWAL(): Promise<any> {

		return new Promise((resolve)=>{
			// Para info, error, y success solo el boton aceptar
			var buttons: any;
			buttons = { confirm: {
								    text: "Aceptar",
								    value: true,
								    visible: true,
								    className: "",
								    closeModal: true
								}
						};

			switch(this.type) {
				case DialogTypes.INFO:
					this.title = 'Información';
					this.icon = 'info';
					break;
				case DialogTypes.WARNING:
					this.title = 'Atención';
					this.icon = 'warning';
					buttons = {
								cancel: {
								    text: "Cancelar",
								    value: false,
								    visible: true,
								    className: "",
								    closeModal: true,
								},
								confirm: {
								    text: "Aceptar",
								    value: true,
								    visible: true,
								    className: "",
								    closeModal: true
								}
							};
					break;
				case DialogTypes.ERROR:
					this.title = 'Error';
					this.icon = 'error';
					break;
				case DialogTypes.SUCCESS:
					this.title = 'Exito';
					this.icon = 'success';
					break;
				default:
					this.title = 'Información';
					this.icon = 'info';
					break;

			}

			let options = {   
	            title: this.title,   
	            text: this.message,   
	            icon: this.icon,   
	            buttons: buttons,
	            closeOnClickOutside: false
	        };

			resolve(options);
		});
	}
	
	createComponenteSWAL(options:any = null): Promise<boolean> {

		return new Promise((resolve) => {

			swal(options).then((result)=>{

				if ( result ) // true => Aceptar
					resolve(true);
				else
					resolve(false);				
			});

		});
	}
}